import React, { ReactElement } from "react";
import { Link, To } from "react-router-dom";
import { Logo } from "@evvve/ui-kit";
import s from "./logoLink.module.scss";

interface Props {
  path: To;
  theme: "dark" | "light";
}
const LogoLink = ({ path, theme }: Props): ReactElement => (
  <Link
    to={path}
    style={{ display: "block", fontSize: "0px" }}
    className={s.logoLink}
  >
    <Logo theme={theme} />
  </Link>
);

export default LogoLink;
