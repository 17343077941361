import React, { FC } from "react";
import { IAmSpeakerAtTheEventMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: IAmSpeakerAtTheEventMetadata;
};

const IAmSpeaker: FC<Props> = ({ metadata }) => {
  const {
    event_id: eventId,
    event_name: eventTitle,
    event_date: eventDate,
  } = metadata.data;

  return (
    <NotificationTemplate
      title="I'm a speaker at the event"
      dateTime={{ date: eventDate }}
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
    />
  );
};

export default IAmSpeaker;
