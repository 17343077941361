// FIX_ME:
/* eslint-disable no-unsafe-optional-chaining */
import * as Yup from "yup";
import moment, { Moment } from "moment/moment";
import { EMAIL_REGEXP, InputMaxLength, PASSWORD_REGEX } from "../constants";
import { calcEndTime, convertSelectedTimeToMoment, replaceDate } from "./date";
import { EventStatus } from "../store/events/types";

export const MAX_FREE_EVENT_DURATION_IN_HOURS = 3;
export const MAX_PRO_EVENT_DURATION_IN_HOURS = 12;
export const MAX_ATTENDEES_PRO = 1000;
export const MAX_ATTENDEES_FREE = 100;
export const MIN_ATTENDEES = 1;

export const maxLengthTestConfig = (
  maxLength?: number,
): Yup.TestConfig<string | undefined | null> => ({
  name: "max-length",
  message: ({ value }) =>
    maxLength !== undefined &&
    `Character limit (${maxLength}) exceeded by ${value?.length - maxLength}`,
  test: (value: string | undefined | null) =>
    maxLength === undefined ||
    maxLength === null ||
    !value ||
    value.length <= maxLength,
});

export const optional = (maxLength?: number) =>
  Yup.string().trim().test(maxLengthTestConfig(maxLength));

export const maxLen = (maxLength?: number) =>
  Yup.string().notRequired().trim().test(maxLengthTestConfig(maxLength));

export const required = (message: string, maxLength?: number) =>
  Yup.string().trim().required(message).test(maxLengthTestConfig(maxLength));

export const validateFirstName = () =>
  required("Please enter your first name", InputMaxLength.S_24);
export const validateLastName = () =>
  required("Please enter your last name", InputMaxLength.S_24);

export const validateUsername = () =>
  required("Please enter a username", InputMaxLength.S_24).test(
    "is-valid",
    "Username must meet the requirements",
    (username) => /^[A-Za-z0-9.\-_]{3,}$/.test(username),
  );

export const isEmail = (text: string): boolean => EMAIL_REGEXP.test(text);
export const validateEmail = () =>
  required("Please enter your email", InputMaxLength.L_256).test(
    "email",
    "The email format is incorrect",
    (value) => EMAIL_REGEXP.test(value),
  );

export const validateCurrentPassword = () =>
  required("Please enter password", InputMaxLength.M_128);

export const validateNewPassword = () =>
  validateCurrentPassword().matches(
    PASSWORD_REGEX,
    "Password doesn't meet the requirements above",
  );

export const validateNewPasswordRepeat = (refToPassword: string) =>
  required("Please repeat password", InputMaxLength.M_128).oneOf(
    [Yup.ref(refToPassword)],
    "Passwords do not match",
  );

export interface ValidateFileConfig {
  acceptableTypes: string[];
  maxSize: number;
}
export const validateFile = (
  { acceptableTypes, maxSize }: ValidateFileConfig,
  message = "Please check the file format and size",
) =>
  Yup.mixed<File>()
    .notRequired()
    .test(
      "file-type-and-size",
      message,
      (file?: File | null) =>
        !file || (acceptableTypes.includes(file.type) && file.size <= maxSize),
    );

//
export const calcAvailableCharacters = (characterLimit: number, value = "") =>
  characterLimit - value?.length;

export const eventStartTimeFilter = (
  eventTzOffset: number,
  val: Date, // item
  now: Date,
  eventStartDate?: Moment,
): boolean => {
  if (!eventStartDate) return true;

  if (eventStartDate.isAfter(now, "days")) return true;
  if (eventStartDate.isSame(now, "days")) {
    const valCloned = new Date(val);
    valCloned.setFullYear(
      eventStartDate.year(),
      eventStartDate.month(),
      eventStartDate.date(),
    );

    return (
      convertSelectedTimeToMoment(eventTzOffset, valCloned).diff(now, "minutes") >= 0
    );
  }

  return false;
};

export const eventEndTimeFilter = (
  eventTzOffset: number,
  val: Date,
  eventStart?: Moment,
  maxEventEnd?: Moment,
): boolean => {
  if (!eventStart || !maxEventEnd) return true;

  const valWithTz = convertSelectedTimeToMoment(eventTzOffset, val);
  const valWithTzAndDate = calcEndTime(valWithTz, eventStart);

  return valWithTzAndDate > eventStart && valWithTzAndDate <= maxEventEnd;
};

export const sessionStartTimeFilter = (
  eventTzOffset: number,
  val: Date,
  eventStart: Moment,
  eventEnd: Moment,
) => {
  const time = replaceDate(
    convertSelectedTimeToMoment(eventTzOffset, val),
    eventStart,
  );
  if (time < eventStart) {
    time.add(1, "days"); // time ends after midnight, next calendar day
  }

  return time >= eventStart && time < eventEnd;
};

export const sessionEndTimeFilter = (
  eventTzOffset: number,
  val: Date | string,
  sessionStart: moment.Moment | undefined,
  eventEnd: moment.Moment,
) => {
  const start = sessionStart ?? moment();
  const time = replaceDate(convertSelectedTimeToMoment(eventTzOffset, val), start);
  if (time < start) {
    time.add(1, "days");
  }

  return time > start && time <= eventEnd;
};

export const isEventReadonly = (eventStatus?: EventStatus) =>
  eventStatus
    ? ![EventStatus.DRAFT, EventStatus.UPCOMING].some((s) => s === eventStatus)
    : true;
