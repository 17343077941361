import React, { FC } from "react";

import { EventDetailsHaveChangedMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: EventDetailsHaveChangedMetadata;
};

const EventDetailsHaveChanged: FC<Props> = ({ metadata }) => {
  const {
    event_id: eventId,
    event_name: eventName,
    new_date: newDate,
    new_start_time: newStartTime,
    new_end_time: newEndTime,
  } = metadata.data;

  return (
    <NotificationTemplate
      title="Event details have changed"
      event={{ title: eventName, link: `/catalog/event/${eventId}` }}
      dateTime={{ date: newDate, startTime: newStartTime, endTime: newEndTime }}
    />
  );
};

export default EventDetailsHaveChanged;
