// FIX_ME:
/* eslint-disable no-plusplus */
export const convertNumberThousandsToChars = (number: number) => {
  if (number < 1000) {
    return `${number}`;
  }
  const names = ["K", "M", "B"];
  let val = number;
  let step = 0;
  while (val >= 1) {
    val /= 1000;
    if (val < 1000) {
      return `${Math.floor(val)} ${names[step]}`;
    }
    step++;
  }
};

export enum RoundType {
  RoundUp = "roundUp", // Math.ceil
  RoundDown = "roundDown", // Math.floor
  NearestHalfUp = "nearestHalfUp", // Math.round
}

const roundFunctions: Record<RoundType, (number: number) => number> = {
  [RoundType.RoundUp]: (num) => Math.ceil(num),
  [RoundType.RoundDown]: (num) => Math.floor(num),
  [RoundType.NearestHalfUp]: (num) => Math.round(num),
};

const numberAbbreaviationMap: Record<number, string> = {
  1: "K", // abs of numbers that belongs the range [1_000, 1_000_000)
  2: "M", // abs of numbers that belongs the range [1_000_000, 1_000_000_000)
  3: "B", // abs of numbers that belongs the range [1_000_000_000, 1_000_000_000_000)
};

const removeTrailingZeros = (number: string): string => {
  const [whole, decimal] = number.split(".");
  const lastNonZeroIndex = decimal
    .split("")
    .reduce<
      null | number
    >((prevIndex, curVal, curIndex) => (curVal !== "0" ? curIndex : prevIndex), null);

  const clearedDecimal =
    lastNonZeroIndex === null ? "" : decimal.slice(0, lastNonZeroIndex + 1);

  return clearedDecimal.length ? `${whole}.${clearedDecimal}` : whole;
};

const roundNumber = (
  number: number,
  numberOfDecimals = 2,
  roundType = RoundType.NearestHalfUp,
) => {
  if (!(roundType in roundFunctions)) {
    throw new Error(`Invalid round type: ${roundType}`);
  }

  const roundedNumber =
    roundFunctions[roundType](number * 10 ** numberOfDecimals) /
    10 ** numberOfDecimals;

  return roundedNumber.toFixed(numberOfDecimals);
};

export function abbreviateNumber(
  number: number,
  numberOfDecimals = 2,
  roundType = RoundType.NearestHalfUp,
) {
  if (number === 0) return "0";

  const isNegataive = number < 0;
  const absNumber = Math.abs(number);

  // let's say we want number to represent in such form: n * 10^(3*k)
  // and then round the n with particular amount of signs after dot
  // it's the essence of the task

  // maxDegMulOf3 is the k in the presented formaula
  const maxDegMulOf3 = Math.floor(Math.log10(absNumber) / 3);

  // if maxDegMulOf3 < 0 then the number is less then 1K and we just round this
  if (maxDegMulOf3 < 0) {
    const roundedNumber = roundNumber(number, numberOfDecimals, roundType);

    return removeTrailingZeros(roundedNumber);
  }

  // if maxDegMulOf3 > 3 then the number more or equal than trillion we can't handle such number
  if (maxDegMulOf3 > 3) throw new Error(`very big number to abbreviate: ${number}`);

  // dividedNumber is the n in the presented formula
  const dividedNumber = absNumber / 10 ** (3 * maxDegMulOf3);

  // recovered divided number with the sign
  const rDividedNumber = isNegataive ? dividedNumber * -1 : dividedNumber;

  const roundedNumber = roundNumber(rDividedNumber, numberOfDecimals, roundType);
  const resultNumber = removeTrailingZeros(roundedNumber);

  // if maxDegMulOf3 = 3 then the number less then 1000 and it's no need to abbreviate and we just return rounded number
  if (maxDegMulOf3 === 0) return resultNumber;

  return resultNumber + numberAbbreaviationMap[maxDegMulOf3];
}

export function separateNumberInGroups(number: number) {
  const sign = number < 0 ? "-" : "";
  const abs = Math.abs(number);

  const [whole, decimal] = abs.toString().split(".");

  const groupCount = Math.ceil(whole.length / 3);

  const reversedWholeArray = whole.split("").reverse();

  const groupedWhole = Array(groupCount)
    .fill("")
    .map((_, index) =>
      reversedWholeArray
        .slice(index * 3, (index + 1) * 3)
        .reverse()
        .join(""),
    )
    .reverse()
    .join(" ");

  return sign + groupedWhole + (decimal ? `.${decimal}` : "");
}

export function separateAbbreviateNumber(value: number) {
  return Math.log10(value) >= 6
    ? abbreviateNumber(value, 1, RoundType.RoundDown)
    : separateNumberInGroups(value);
}
