import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { BASE_API_URL } from "../../../constants";
import { AddToCalendarAuthGoogleDTO } from "../model/types";

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}`,
  prepareHeaders: (headers) => {
    headers.set("Access-Control-Allow-Origin", "*");
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    localStorage.getItem("access-token") &&
      headers.set("Authorization", `Bearer ${localStorage.getItem("access-token")}`);

    return headers;
  },
});

export const apiAddToCalendar = createApi({
  reducerPath: "apiAddToCalendar",
  baseQuery,
  endpoints: (builder) => ({
    // CSV

    getCSV: builder.mutation<string, string>({
      query: (eventId: string) => ({
        url: `/events/${eventId}/download-csv`,
        method: "POST",
        responseHandler: (response) => response.text(),
      }),
    }),

    // Google

    getGoogleAuthorizationUrl: builder.query<AddToCalendarAuthGoogleDTO, void>({
      query: () => "/auth/google-authorization-url",
    }),

    addToGoogleCalendar: builder.mutation({
      query: ({ eventId, data }) => ({
        url: `/events/${eventId}/add-google-calendar`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetCSVMutation,
  useAddToGoogleCalendarMutation,
  useGetGoogleAuthorizationUrlQuery,
} = apiAddToCalendar;
