import React from "react";
import s from "./style.module.scss";

const ProfileSkeleton = () => (
  <div className={s.wrap}>
    <div className={s.bigMessage} />
    <div className={s.jobInfo}>
      <div className={s.position} />
      <div className={s.company} />
    </div>
    <div className={s.icons}>
      <div className={s.icon} />
      <div className={s.icon} />
      <div className={s.icon} />
      <div className={s.icon} />
    </div>
  </div>
);

export default ProfileSkeleton;
