import Link from "src/shared-features/Link";
import { Button } from "@evvve/ui-kit";
import { useAppSelector } from "src/hooks/useStoreHooks";
import cn from "classnames";
import style from "./style.module.scss";
import { useLogout } from "../../../../authorization/hooks/useLogout";

export interface Props {
  openMenu?: boolean;
}

const HeaderMenu = ({ openMenu }: Props) => {
  const user = useAppSelector((state) => state.user.userInfo);

  const responsiveLayout = useAppSelector(
    (state) => state.user.responsiveLayoutConfig,
  );

  const { onLogOut } = useLogout();

  return responsiveLayout ? (
    <div
      className={cn({
        [style.wrap]: openMenu,
        [style.close]: !openMenu,
        [style.wrap_mobile]: responsiveLayout.isMobile,
      })}
    >
      <Link to={`/profile/${user?.username}`} hoverUnderLine={false} color="violet">
        Profile
      </Link>
      <hr className={`${style.divider} solid`} />
      <Link to="/" hoverUnderLine={false} color="violet">
        Homepage
      </Link>
      <Link to="/catalog" hoverUnderLine={false} color="violet">
        Event catalog
      </Link>
      <Link to="/contacts" hoverUnderLine={false} color="violet">
        Contacts
      </Link>
      <hr className={`${style.divider} solid`} />
      <Link to="/privacy" hoverUnderLine={false} color="violet">
        Privacy Policy
      </Link>
      <Link to="/terms" hoverUnderLine={false} color="violet">
        Terms of Service
      </Link>
      {/* <Link to="/learning" hoverUnderLine={false} color="violet"> */}
      {/*          Learning */}
      {/* </Link> */}
      <hr className={`${style.divider} solid`} />
      <Button theme="red" type="flat" size="s" onClick={onLogOut}>
        Log out
      </Button>
    </div>
  ) : (
    <></>
  );
};
export default HeaderMenu;
