import { currentUserApi } from "./api";
import { CurrentUser, GetUserResponse } from "./types";
import { setUserInfo } from "./slice";

export const updateUserCardInChat = (userId: string) =>
  currentUserApi.endpoints.getUser.initiate({ userId }, { forceRefetch: true });

export const updateCachedUserCardInChat = (updatedUser: GetUserResponse) =>
  currentUserApi.util.updateQueryData(
    "getUser",
    { userId: updatedUser.user.unique_id },
    () => updatedUser,
  );

export const updateFollowingCountInProfile = (
  currentUser: CurrentUser,
  action: "follow" | "unfollow",
) => {
  const newFollowingCount =
    action === "unfollow"
      ? (currentUser.following_count || 1) - 1
      : (currentUser.following_count || 0) + 1;

  return setUserInfo({
    ...currentUser,
    following_count: newFollowingCount,
  });
};

export const refetchFollowingList = currentUserApi.endpoints.getFollowings.initiate(
  undefined,
  { forceRefetch: true },
);
