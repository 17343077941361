export enum SystemMessageVariant {
  WELCOME_TO_PLATFORM = "welcome_to_evvve",
  INVITATION_TO_GO_LIVE = "invitation_to_go_live",
  EVENT_STARTS_IN_3_HOURS = "the_event_starts_in_3_hours",
  EVENT_STARTS_IN_15_MIN = "the_event_starts_in_15_min",
  EVENT_IS_LIVE = "the_event_is_live",
  I_AM_SPEAKER_AT_EVENT = "i_am_speaker_at_the_event",
  I_AM_ORGANIZING_EVENT = "i_am_organizing_the_event",
  I_WILL_NOT_BE_SPEAKER_AT_EVENT = "i_wont_be_a_speaker_at_the_event",
  I_ACCEPTED_TO_BE_SPEAKER_AT_EVENT = "i_accepted_to_be_a_speaker_at_the_event",
  WELCOME_TO_EVENT = "welcome_to_the_event",
  EVENT_HAS_BEEN_CANCELLED = "the_event_has_been_cancelled",
  NEW_SPEAKER_AT_EVENT = "new_speaker_at_the_event",
  EVENT_DETAILS_HAVE_CHANGED = "event_details_have_changed",
  YOU_HAVE_BEEN_INVITED_TO_BE_SPEAKER = "youve_been_invited_to_be_a_speaker",
  EVENT_DURATION_EXCEEDED = "event_duration_exceeded",
}

export type IAmSpeakerAtTheEventMetadata = {
  type: SystemMessageVariant.I_AM_SPEAKER_AT_EVENT;
  data: {
    event_name: string;
    event_id: string;
    event_date: string;
  };
};

export type YouHaveBeenInvitedToBeASpeakerMetadata = {
  type: SystemMessageVariant.YOU_HAVE_BEEN_INVITED_TO_BE_SPEAKER;
  data: {
    event_name: string;
    event_id: string;
    event_date: string;
    organizer_id: string;
  };
};

export type EventDetailsHaveChangedMetadata = {
  type: SystemMessageVariant.EVENT_DETAILS_HAVE_CHANGED;
  data: {
    event_name: string;
    event_id: string;
    new_date?: string;
    new_start_time?: string;
    new_end_time?: string;
  };
};

export type NewSpeakerAtTheEventMetadata = {
  type: SystemMessageVariant.NEW_SPEAKER_AT_EVENT;
  data: {
    event_name: string;
    event_id: string;
    speaker_id: string;
  };
};

export type WelcomeToTheEventMetadata = {
  type: SystemMessageVariant.WELCOME_TO_EVENT;
  data: {
    event_name: string;
    event_id: string;
  };
};

export type TheEventHasBeenCancelledMetadata = {
  type: SystemMessageVariant.EVENT_HAS_BEEN_CANCELLED;
  data: {
    event_name: string;
  };
};

export type IAcceptedToBeASpeakerAtTheEventMetadata = {
  type: SystemMessageVariant.I_ACCEPTED_TO_BE_SPEAKER_AT_EVENT;
  data: {
    event_name: string;
    event_id: string;
    event_date: string;
    event_price: string;
  };
  sender_id: string;
};

export type IAmOrganizingTheEventMetadata = {
  type: SystemMessageVariant.I_AM_ORGANIZING_EVENT;
  data: {
    event_name: string;
    event_id: string;
    event_date: string;
  };
};

export type IWillNotBeASpeakerAtTheEventMetadata = {
  type: SystemMessageVariant.I_WILL_NOT_BE_SPEAKER_AT_EVENT;
  data: {
    event_name: string;
    event_id: string;
  };
};

export type InvitationToGoLiveMetadata = {
  type: SystemMessageVariant.INVITATION_TO_GO_LIVE;
  data: {
    event_id: string;
    session_id: string;
    cancelled_by_speaker: boolean;
    cancelled_by_organizer: boolean;
  };
  sender_id: string;
};

export type EventDurationExceededMetadata = {
  type: SystemMessageVariant.EVENT_DURATION_EXCEEDED;
};

export type WelcomeToEvvveMetadata = {
  type: SystemMessageVariant.WELCOME_TO_PLATFORM;
};

export type TheEventStartsIn3HoursMetadata = {
  type: SystemMessageVariant.EVENT_STARTS_IN_3_HOURS;
  data: {
    event_name: string;
    event_id: string;
  };
};

export type TheEventStartsIn15MinMetadata = {
  type: SystemMessageVariant.EVENT_STARTS_IN_15_MIN;
  data: {
    event_name: string;
    event_id: string;
  };
};

export type TheEventIsLiveMetadata = {
  type: SystemMessageVariant.EVENT_IS_LIVE;
  data: {
    event_name: string;
    event_id: string;
  };
};

export type SystemMetadata =
  | IAmSpeakerAtTheEventMetadata
  | EventDurationExceededMetadata
  | YouHaveBeenInvitedToBeASpeakerMetadata
  | EventDetailsHaveChangedMetadata
  | NewSpeakerAtTheEventMetadata
  | TheEventHasBeenCancelledMetadata
  | WelcomeToTheEventMetadata
  | IAcceptedToBeASpeakerAtTheEventMetadata
  | IWillNotBeASpeakerAtTheEventMetadata
  | IAmOrganizingTheEventMetadata
  | InvitationToGoLiveMetadata
  | WelcomeToEvvveMetadata
  | TheEventStartsIn3HoursMetadata
  | TheEventStartsIn15MinMetadata
  | TheEventIsLiveMetadata;
