import { useEffect } from "react";
import { initializeAnalytics } from "./analytics";

const GoogleAnalytics = () => {
  useEffect(() => {
    const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

    if (gaTrackingId) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
      document.head.appendChild(script);

      script.onload = () => {
        initializeAnalytics(gaTrackingId);
      };
    }
  }, []);

  return null;
};

export default GoogleAnalytics;
