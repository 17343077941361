import React from "react";
import { messengerApi } from "src/store/messenger";
import cn from "classnames";
import s from "./styles.module.scss";
import { useMessengerViewController } from "./hooks";
import { MessengerViewVariant } from "./types";
// eslint-disable-next-line import/no-cycle
import { ProfileView, ParticipantListView, ChatListView, ChatRoom } from "./ui";

const Messenger = () => {
  const { chatId, profileId, currentView } = useMessengerViewController();

  messengerApi.useSubscribeToEventsQuery(null);

  const isProfileView = currentView === MessengerViewVariant.PROFILE;

  const renderMessengerView = () => {
    switch (currentView) {
      case MessengerViewVariant.CHAT_BY_ID:
        return chatId ? <ChatRoom /> : <div>no chat id</div>;
      case MessengerViewVariant.CHAT_PARTICIPANTS:
        return chatId ? <ParticipantListView /> : <div>no chat id</div>;
      case MessengerViewVariant.PROFILE:
        return profileId ? <ProfileView /> : <div>no profile id</div>;
      default:
        return <ChatListView />;
    }
  };

  return (
    <div className={cn(s.wrap, { [s.profileView]: isProfileView })}>
      {renderMessengerView()}
    </div>
  );
};

export default Messenger;
