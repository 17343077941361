import React, { FC } from "react";
import { IAcceptedToBeASpeakerAtTheEventMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";
import { useAppSelector } from "../../../../../../../../../hooks/useStoreHooks";

type Props = {
  metadata: IAcceptedToBeASpeakerAtTheEventMetadata;
};

const IAcceptedToBeSpeaker: FC<Props> = ({ metadata }) => {
  const {
    data: { event_id: eventId, event_date: eventDate, event_name: eventTitle },
    sender_id: senderId,
  } = metadata;

  const thisUserId = useAppSelector((state) => state.user.userInfo?.unique_id);
  const isThisUserSender = thisUserId === senderId;

  return (
    <NotificationTemplate
      title="I accepted to be a speaker at the event"
      dateTime={{ date: eventDate }}
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
      position={isThisUserSender ? "right" : "left"}
    />
  );
};

export default IAcceptedToBeSpeaker;
