import React, { FC } from "react";
import { TheEventStartsIn3HoursMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: TheEventStartsIn3HoursMetadata;
};

const EventStartsIn3Hours: FC<Props> = ({ metadata }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate
      title="The event start in 3 hours"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
    />
  );
};

export default EventStartsIn3Hours;
