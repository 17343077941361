/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";
import Personal from "src/close-part/pages/AccountSettings/features/Personal/PersonalSettings";
import SocialMedia from "src/close-part/pages/AccountSettings/features/SocialMedia/SocialMediaSettings";
import Privacy from "src/close-part/pages/AccountSettings/features/Privacy/PrivacySettings";
import AccessDetails from "src/close-part/pages/AccountSettings/features/AccessDetails";
import { BroadcastWrapper } from "./shared-features/Wrappers/BroadcastWrapper";

import {
  ClosePartWrapper,
  AuthPartWrapper,
  ToastContainer,
  AppWrapper,
  WrappersApply,
} from "./shared-features/Wrappers";
import { initAmplitude } from "./helpers/amplitude";
import RedirectIfUrlUsernameIsNotThisUser from "./shared-features/Wrappers/RedirectIfUrlUsernameIsNotThisUser";
import { isOtherUserProfileEnabled } from "./helpers/featureFlags";
import { ChatWrapper } from "./shared-features/Wrappers/ChatWrapper";
import { GoogleAnalytics } from "./infrastructure/GoogleAnalytics";

// open part
const IndexPage = loadable(() => import("src/open-part/pages/Index"));
const PricingPage = loadable(() => import("src/open-part/pages/Pricing/Pricing"));
// const LearningPage = loadable(() => import("src/open-part/pages/Learning"));
// const LearningOnePage = loadable(
//   () => import("src/open-part/pages/LearningOnePage")
// );
const EventCatalogPage = loadable(() => import("src/open-part/pages/EventCatalog"));
const AboutUsPagePage = loadable(() => import("src/open-part/pages/AboutUs"));
const CareersPage = loadable(() => import("src/open-part/pages/Careers"));
const Vacancy = loadable(() => import("src/open-part/pages/Vacancy/VacancyPage"));
const ContactUsPage = loadable(() => import("src/open-part/pages/ContactUs"));
const PrivacyPolicyPage = loadable(
  () => import("src/open-part/pages/PrivacyPolicy"),
);
const ServiceTermsPage = loadable(
  () => import("src/open-part/pages/ServiceTerms/ServiceTermsPage"),
);
const PasswordRecovery = loadable(
  () => import("src/authorization/features/PasswordRecovery"),
);
const RegisterOrLogin = loadable(
  () => import("src/authorization/features/RegisterOrLogin"),
);
const FeaturesPage = loadable(() => import("src/open-part/pages/Features"));
const NotFound404Page = loadable(() => import("src/open-part/pages/404"));
const EventLanding = loadable(() => import("src/open-part/pages/EventLanding"));
const EventLandingInvite = loadable(
  () => import("src/open-part/pages/EventLandingInvite"),
);

// close part
const AccountPage = loadable(() => import("src/close-part/pages/Account/index"));
const AccountSettingsPage = loadable(
  () => import("src/close-part/pages/AccountSettings/index"),
);
const ChoosePaymentPlanPage = loadable(
  () => import("src/close-part/pages/ChoosePaymentPlan/index"),
);
const EventCreationPage = loadable(
  () => import("src/close-part/pages/EventCreation/index"),
);
const SuccessfulPaymentPage = loadable(
  () => import("src/close-part/pages/SuccessfulPaymentPage/SuccessfulPaymentPage"),
);
const CancelPaymentPage = loadable(
  () => import("src/close-part/pages/CancelPayment"),
);
const EventCompletionPage = loadable(
  () => import("src/close-part/pages/EventCompletionPage/EventCompletionPage"),
);
const EventSpacePage = loadable(() => import("src/close-part/pages/EventSpace"));
const SettingNewPasswordPage = loadable(
  () => import("src/authorization/features/SettingNewPassword/SettingNewPassword"),
);
const SettingNewEmail = loadable(
  () => import("src/close-part/pages/AccountSettings/features/ChangeEmail"),
);
const CheckMediaDevices = loadable(
  () => import("src/close-part/pages/CheckAudioVideoDevices/CheckAudioVideoDevices"),
);
const GoogleAuthCallback = loadable(
  () => import("src/shared-features/AddToCalendar/ui/GoogleAuthCallback"),
);

// urls
export const getEventSpaceUrl = (eventId: string) =>
  `/catalog/event/${eventId}/space/lobby`;

initAmplitude({
  defaultTracking: {
    sessions: false,
    pageViews: false,
    formInteractions: false,
    fileDownloads: false,
  },
});

function App() {
  return (
    <React.Suspense fallback={<span>Loading...</span>}>
      <GoogleAnalytics />
      <Router>
        <WrappersApply wrappers={[AppWrapper, ToastContainer]}>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="about" element={<AboutUsPagePage />} />
            <Route path="contacts" element={<ContactUsPage />} />
            <Route path="features" element={<FeaturesPage />} />
            <Route path="terms" element={<ServiceTermsPage />} />
            <Route path="catalog" element={<EventCatalogPage />} />
            <Route path="catalog/event/:id" element={<EventLanding />} />
            <Route
              path="catalog/event-invite/:id"
              element={<EventLandingInvite />}
            />
            <Route path="pricing" element={<PricingPage />} />
            {/* <Route path="learning/:id" element={<LearningOnePage />} /> */}
            <Route path="privacy" element={<PrivacyPolicyPage />} />
            <Route path="careers" element={<CareersPage />} />
            <Route path="careers/vacancy/:id" element={<Vacancy />} />
            <Route path="reset-password" element={<SettingNewPasswordPage />} />
            <Route
              path="api/auth/google-callback"
              element={<GoogleAuthCallback />}
            />
            <Route path="/" element={<AuthPartWrapper />}>
              <Route path="login" element={<RegisterOrLogin />} />
              <Route path="recover-password" element={<PasswordRecovery />} />
            </Route>
            <Route
              path="/"
              element={
                <WrappersApply
                  wrappers={[ClosePartWrapper, ChatWrapper, BroadcastWrapper]}
                />
              }
            >
              <Route
                path="profile/:username"
                element={
                  isOtherUserProfileEnabled ? (
                    <AccountPage />
                  ) : (
                    <RedirectIfUrlUsernameIsNotThisUser>
                      <AccountPage />
                    </RedirectIfUrlUsernameIsNotThisUser>
                  )
                }
              />
              <Route path="profile-settings" element={<AccountSettingsPage />}>
                <Route path="personal" element={<Personal />} />
                <Route path="social-media" element={<SocialMedia />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="access" element={<AccessDetails />} />
                <Route path="set-new-email" element={<SettingNewEmail />} />
              </Route>
              <Route path="plans" element={<ChoosePaymentPlanPage />} />
              <Route
                path="payment/success/:eventId"
                element={<SuccessfulPaymentPage />}
              />
              <Route
                path="payment/cancel/:eventId"
                element={<CancelPaymentPage />}
              />
              <Route path="create-event/:plan" element={<EventCreationPage />} />
              <Route
                path="event-completion/:type/:eventId"
                element={<EventCompletionPage />}
              />
              <Route path="catalog/event/:id/space" element={<EventSpacePage />}>
                <Route path="analytics" element={null} />
                <Route path="settings" element={null} />
                <Route path="lobby" element={null} />
                <Route path="stage" element={null} />
              </Route>
              <Route path="/check-devices" element={<CheckMediaDevices />} />
            </Route>
            <Route path="*" element={<NotFound404Page />} />
          </Routes>
        </WrappersApply>
      </Router>
    </React.Suspense>
  );
}

export default App;
