import { FC, useCallback } from "react";

import { Button, Text } from "@evvve/ui-kit";
import { CurrentUser } from "src/store/User/types";
import s from "./style.module.scss";
import { ReactComponent as MessageOffIcon } from "./assets/message_off.svg";
import { useOpenNewPrivateChat } from "./hooks/useOpenNewPrivateChat";

type Props = {
  userFirstName?: CurrentUser["first_name"];
  isAbleToChat?: boolean;
  userId?: string;
};

export const MessageButton: FC<Props> = ({
  userId,
  userFirstName,
  isAbleToChat,
}) => {
  const { isInitingChat, openNewPrivateChat } = useOpenNewPrivateChat();

  const initAndOpenChatWithUser = useCallback(() => {
    if (!userId) throw new Error("[CHAT]: userId doesn't exist");
    openNewPrivateChat(userId);
  }, [openNewPrivateChat, userId]);

  return isAbleToChat ? (
    <Button
      theme="violet"
      type="flat"
      size="s"
      onClick={initAndOpenChatWithUser}
      startIcon="MessageSquare"
      disabled={isInitingChat}
      className={s.messageButton}
    >
      Message
    </Button>
  ) : (
    <div className={s.messagesRestricted}>
      <MessageOffIcon />
      <Text size="s" color="grayscale600">
        {`${userFirstName} has restricted\nmessages from other users`}
      </Text>
    </div>
  );
};
