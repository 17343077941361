import React, { FC } from "react";
import { TheEventHasBeenCancelledMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: TheEventHasBeenCancelledMetadata;
};

const EventHasBeenCancelled: FC<Props> = ({ metadata }) => (
  <NotificationTemplate
    title="The event has been cancelled"
    event={{ title: metadata.data.event_name }}
  />
);

export default EventHasBeenCancelled;
