import React, { createContext, PropsWithChildren } from "react";
import { io } from "socket.io-client";
import { Outlet } from "react-router-dom";

type ChatSocketConnection =
  | { chatSocket: undefined | ReturnType<typeof io> | null; connecting: boolean }
  | null
  | undefined;

export const ChatSocketIoContext = createContext<ChatSocketConnection>(undefined);

export const ChatWrapper: React.FC<PropsWithChildren> = ({ children }) =>
  children ? <>{children}</> : <Outlet />;
