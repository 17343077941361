import { Button } from "@evvve/ui-kit";
import {
  FC,
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Nullable } from "src/shared/utils";
import s from "./styles.module.scss";

const MAX_LINES_IN_CLOSED_MODE = 2;

interface Props extends PropsWithChildren {
  // NOTE: only in px, eg: 24
  lineHeight: number;
  // NOTE: max lines in closed mode, eg: 2
  maxLines?: number;
  initialOpened?: boolean;
}

const ExpandableText: FC<Props> = ({
  children,
  lineHeight,
  maxLines = MAX_LINES_IN_CLOSED_MODE,
  initialOpened = false,
}) => {
  const outerRef = useRef<Nullable<HTMLDivElement>>(null);
  const innerRef = useRef<Nullable<HTMLDivElement>>(null);

  const [isOpened, setIsOpened] = useState<Nullable<boolean>>(initialOpened);

  const [heightOfOpenedContent, setHeightOfOpenedContent] = useState<number>();
  const requestedHeightOfClosedContent = maxLines * lineHeight;

  useEffect(() => {
    if (!innerRef.current) return;
    setHeightOfOpenedContent(innerRef.current.offsetHeight);
  }, [innerRef]);

  const heightOfClosedContent = useMemo(() => {
    if (!heightOfOpenedContent) return null;

    return requestedHeightOfClosedContent < heightOfOpenedContent
      ? requestedHeightOfClosedContent
      : null;
  }, [heightOfOpenedContent, requestedHeightOfClosedContent]);

  const isTruncated = useMemo(
    () =>
      heightOfClosedContent && heightOfOpenedContent
        ? heightOfClosedContent < heightOfOpenedContent
        : false,
    [heightOfClosedContent, heightOfOpenedContent],
  );

  const toggle = () => {
    setIsOpened((state) => !state);
  };

  const toggleLabel = isOpened ? "Less" : "More";

  const height = useMemo(() => {
    if (isOpened) return "auto";

    return heightOfClosedContent ? `${heightOfClosedContent}px` : "auto";
  }, [heightOfClosedContent, isOpened]);

  return (
    <div>
      <div ref={outerRef} style={{ height }} className={s.container}>
        <div
          ref={innerRef}
          className={s.content}
          style={{ lineHeight: `${lineHeight}px` }}
        >
          {children}
        </div>
      </div>
      {isTruncated && (
        <Button className={s.moreLessButton} onClick={toggle} size="xs" type="flat">
          {toggleLabel}
        </Button>
      )}
    </div>
  );
};

export default memo(ExpandableText);
