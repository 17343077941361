import { useEffect, useState } from "react";
import DeviceDetector from "device-detector-js";
import { DeviceType } from "device-detector-js/dist/typings/device";

export const useDeviceDetector = (): {
  userDevice: DeviceType | undefined;
  isIPadChrome: boolean;
  isTouchDevice: boolean;
} => {
  const { userAgent } = window.navigator;

  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(userAgent).device?.type;

  const [userDevice, setUserDevice] = useState<DeviceType | undefined>(undefined);
  const [isIPadChrome, setIsIPadChrome] = useState(false);
  const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

  useEffect(() => {
    if (!device && !userAgent) return;

    if (!device && userAgent.includes("iPhone")) {
      // device is undefined on iPhone
      setUserDevice("smartphone");
    } else if (!device && userAgent.includes("iPad")) {
      // iPad, iOS 15
      // iPad Chrome (userAgent on iPad Safari is equivalent to userAgent on MacBook)
      setUserDevice("tablet");
      setIsIPadChrome(true);
    } else if (
      device === "desktop" &&
      window.screen.width >= 768 &&
      window.screen.width <= 1024
    ) {
      // iPad, iOS later version
      setUserDevice("tablet");
      setIsIPadChrome(true);
    } else {
      setUserDevice(device);
      if (userAgent.includes("iPad")) {
        setIsIPadChrome(true);
      }
    }
  }, [userAgent, device]);

  return { userDevice, isIPadChrome, isTouchDevice };
};
