import React, { FC } from "react";

import { useGetUserQuery } from "src/store/User/api";

import { YouHaveBeenInvitedToBeASpeakerMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";
import { useAppSelector } from "../../../../../../../../../hooks/useStoreHooks";

type Props = {
  metadata: YouHaveBeenInvitedToBeASpeakerMetadata;
};

const YouInvitedAsSpeaker: FC<Props> = ({ metadata }) => {
  const {
    organizer_id: organizerId,
    event_date: eventDate,
    event_id: eventId,
    event_name: eventTitle,
  } = metadata.data;

  const thisUserId = useAppSelector((state) => state.user.userInfo?.unique_id);
  const isThisUserSender = thisUserId === organizerId;

  const { data } = useGetUserQuery({ userId: organizerId });
  const userName = data ? data.user.first_name + data.user.last_name : "Unknown";

  return (
    <NotificationTemplate
      title="You’ve been invited to be a speaker at"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
      dateTime={{ date: eventDate }}
      user={
        data && {
          name: userName,
          avatar_url: data.user.avatar_url,
          role: "Organizer",
        }
      }
      position={isThisUserSender ? "right" : "left"}
    />
  );
};

export default YouInvitedAsSpeaker;
