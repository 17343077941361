import React, { FC } from "react";
import { TheEventIsLiveMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: TheEventIsLiveMetadata;
};

const EventIsLive: FC<Props> = ({ metadata }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate
      title="The event is live!"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
    />
  );
};

export default EventIsLive;
