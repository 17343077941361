/* eslint-disable implicit-arrow-linebreak */
import React, { FC, useCallback, useMemo } from "react";
import { Button } from "@evvve/ui-kit";
import { useFollowOrUnfollowUserMutation } from "src/store/User/api";
import {
  refetchFollowingList,
  updateCachedUserCardInChat,
  updateFollowingCountInProfile,
} from "src/store/User/followOrUnfollow";
import { useAppDispatch, useAppSelector } from "src/hooks/useStoreHooks";
import s from "./style.module.scss";

type Props = {
  amIFollower: boolean;
  userId?: string;
  disabled: boolean;
};

export const SubscriptionButton: FC<Props> = ({ amIFollower, userId, disabled }) => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.user.userInfo);

  const [followOrUnfollow, { isLoading: isFollowOrUnfollowProcessing }] =
    useFollowOrUnfollowUserMutation();

  const onToggleSubscription = useCallback(async () => {
    if (!userId || !currentUser) return;

    const updatedFollowedOrUnfollowedUser = await followOrUnfollow({
      doIFollowUser: amIFollower,
      userId,
    }).unwrap();

    // Counter in Profile renders the currentUser.following_count instead of followingList.length,
    // so we need to update both (counter and Following list in user card in Profile)
    dispatch(refetchFollowingList);
    dispatch(
      updateFollowingCountInProfile(
        currentUser,
        amIFollower ? "unfollow" : "follow",
      ),
    );
    // Update counters in chat profile of un/followed user with userId
    dispatch(updateCachedUserCardInChat(updatedFollowedOrUnfollowedUser));
  }, [userId, currentUser, followOrUnfollow, amIFollower, dispatch]);

  const startIconName = useMemo(() => {
    if (isFollowOrUnfollowProcessing) return undefined;

    return amIFollower ? "UserMinus" : "UserPlus";
  }, [isFollowOrUnfollowProcessing, amIFollower]);

  const text = amIFollower ? "Unfollow" : "Follow";

  return (
    <Button
      theme="violet"
      type="solid"
      size="s"
      onClick={onToggleSubscription}
      startIcon={startIconName}
      disabled={isFollowOrUnfollowProcessing || disabled}
      isLoading={isFollowOrUnfollowProcessing}
      className={s.followButton}
    >
      {text}
    </Button>
  );
};
