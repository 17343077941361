import { FC, useRef, useState } from "react";
import { Icons } from "@evvve/ui-kit";
import { ChatDTO } from "src/store/messenger";
import { noop } from "lodash";
import commonStyle from "src/styles/common.module.scss";
import { CommonHeader } from "../common-header";
import { ContextMenuWrapper } from "../context-menu-wrapper";
import { useGetChatMateComplex } from "../../../../hooks";

type Props = {
  chatInfo?: ChatDTO;
  chatInfoIsLoading: boolean;
};

const CHAT_HEADER_COMPONENT_KEY = "chatHeader";

const ChatHeader: FC<Props> = ({ chatInfo, chatInfoIsLoading }) => {
  const [contextMenuIsOpened, setContextMenuIsOpened] = useState(false);
  const { chatMateId, chatMateProfile, isFetchingChatMateProfile } =
    useGetChatMateComplex();
  const menuButtonRef = useRef<HTMLDivElement>(null);

  const closeContextMenu = () => {
    setContextMenuIsOpened(false);
  };

  const toggleContextMenu = () => {
    setContextMenuIsOpened((prevState) => !prevState);
  };

  const isSystemChat = chatInfo?.system;

  const commonProps = {
    isLoading: chatInfoIsLoading,
    chatInfo,
    chatMateId,
  };

  return isSystemChat ? (
    <CommonHeader {...commonProps} isSystemChat key={CHAT_HEADER_COMPONENT_KEY} />
  ) : (
    // TODO: пересмотреть часть с враппером, слегка неудобно с ним обращаться
    <ContextMenuWrapper
      menuIsOpened={contextMenuIsOpened}
      closeMenu={closeContextMenu}
      menuButtonRef={menuButtonRef}
      chatInfo={chatInfo}
      chatMateProfile={chatMateProfile}
      isFetchingChatMateProfile={isFetchingChatMateProfile}
      key={CHAT_HEADER_COMPONENT_KEY}
    >
      <CommonHeader
        {...commonProps}
        menuButtonEl={
          <div
            ref={menuButtonRef}
            onClick={toggleContextMenu}
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            className={commonStyle.noSelect}
            aria-labelledby="TODO:"
          >
            <Icons.MoreVertical size="m" color="grayscale600" />
          </div>
        }
      />
    </ContextMenuWrapper>
  );
};

export default ChatHeader;
