import { FC } from "react";

import { WelcomeToTheEventMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: WelcomeToTheEventMetadata;
  text?: string;
};

const WelcomeToEvent: FC<Props> = ({ metadata, text }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate
      title="Welcome to the event"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
    >
      {text && text}
    </NotificationTemplate>
  );
};

export default WelcomeToEvent;
