// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { messengerApi } from "..";

export const refreshChatMembers = ({ chatId }: { chatId: string }) =>
  messengerApi.endpoints.getChatParticipants.initiate(
    { chatId },
    { forceRefetch: true },
  );
export const refreshChatInfo = ({ chatId }: { chatId: string }) =>
  messengerApi.endpoints.getChatInfoById.initiate(
    { chatId },
    { forceRefetch: true },
  );

// NOTE: такие данные приходят на добавление нового юзера в чат
// [CHAT] TODO: [BE] на ChatUpdate приходит две разных (а может и больше) структур данных
// из них непонятно что произошло
// пока это реализовано так - непоняно как
type AddRemoveMemberData = {
  chat_id: string;
};

// [CHAT] TODO: [BE] на ChatUpdate приходит две разных (а может и больше) структур данных
// из них непонятно что произошло
// пока это реализовано так - непоняно как

// приходит, когда редактируем ивент (название, обложка)
export type EventInfoInChatUpdateMessage = {
  avatar_url: string;
  id: string;
  title: string;
};

export type ChatUpdateMessageEvent =
  | AddRemoveMemberData
  | EventInfoInChatUpdateMessage;

// TODO: Херовое название
export const isRefetchChatMembersCase = (
  data: ChatUpdateMessageEvent,
): data is AddRemoveMemberData => "chat_id" in data;

export const isEventInfoInChatUpdateMessage = (
  data: ChatUpdateMessageEvent,
): data is EventInfoInChatUpdateMessage => "title" in data;
