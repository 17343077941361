import React from "react";

import { NotificationTemplate } from "../notification-template";

const eventDurationExceededStatic =
  "Your event was so great that the allotted 3 hours flew by unnoticed. " +
  "Please end the event within 15 minutes, or it will terminate automatically.";

const EventDurationExceeded = () => (
  <NotificationTemplate title="Event duration exceeded">
    {eventDurationExceededStatic}
  </NotificationTemplate>
);

export default EventDurationExceeded;
