import React, { useEffect, useMemo, useState } from "react";
import { noop } from "lodash";
import cn from "classnames";
import { Icons, Text } from "@evvve/ui-kit";
import commonStyle from "src/styles/common.module.scss";
import { SocialMediaLinkType } from "src/store/User/types";
import { useModal } from "src/hooks";
// eslint-disable-next-line import/no-cycle
import { ModalTemplate } from "src/close-part/features/Modals";
import { PersonCardBlockProps } from "src/close-part/features/Person/types";
import Link from "../../Link";
import styles from "./style.module.scss";

const SOCIAL_MEDIA_ICON_BY_TYPE = {
  [SocialMediaLinkType.TWITTER]: Icons.Twitter,
  [SocialMediaLinkType.INSTAGRAM]: Icons.Instagram,
  [SocialMediaLinkType.FACEBOOK]: Icons.Facebook,
  [SocialMediaLinkType.LINKEDIN]: Icons.Linkedin,
  [SocialMediaLinkType.WEBSITE]: Icons.Globe,
};

export const SocialMediaBlock = ({
  profileOwner,
  className,
  isMobile,
}: PersonCardBlockProps) => {
  const [targetLink, setTargetLink] = useState<string>();

  const youAreLeavingEvvveModalControls = useModal();

  useEffect(() => {
    if (!targetLink) return;
    youAreLeavingEvvveModalControls.openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetLink]);

  const closeYouAreLeavingEvvveModal = () => {
    setTargetLink(undefined);
    youAreLeavingEvvveModalControls.closeModal();
  };

  const icons = useMemo(
    () =>
      [
        SocialMediaLinkType.TWITTER,
        SocialMediaLinkType.FACEBOOK,
        SocialMediaLinkType.LINKEDIN,
        SocialMediaLinkType.INSTAGRAM,
        SocialMediaLinkType.WEBSITE,
      ].map((socialMediaType) => {
        const url = profileOwner.social_links?.find(
          ({ type }) => type === socialMediaType,
        )?.url;
        const Icon = SOCIAL_MEDIA_ICON_BY_TYPE[socialMediaType];

        return url && Icon ? (
          <div
            role="button"
            tabIndex={0}
            onClick={() => setTargetLink(url)}
            onKeyDown={noop}
            aria-label={`Link to ${socialMediaType}`}
            className={cn(commonStyle.noSelect, styles.socialMediaIcon)}
          >
            <Icon color="violet600" size="s" />
          </div>
        ) : (
          <></>
        );
      }),
    [profileOwner.social_links],
  );

  return icons.length ? (
    <>
      <div className={cn(styles.socialMediaIcons, className)}>{icons}</div>
      <ModalTemplate
        title="You’re leaving Evvve"
        isOpen={youAreLeavingEvvveModalControls.isOpenModal}
        onClose={closeYouAreLeavingEvvveModal}
        className={cn(styles.youAreLeavingEvvveModal, {
          [styles.youAreLeavingEvvveModal_mobile]: isMobile,
        })}
      >
        <div className={styles.content}>
          <Text size="m">If you trust this link, select it to continue.</Text>
          {targetLink && (
            // TODO kit Link:
            //  text links need "display: flex/inline" to fit in a container with paddings
            <Link
              external
              to={targetLink}
              target="_blank"
              color="violet"
              style={{ display: "inline" }}
              className={styles.test}
            >
              {targetLink}
            </Link>
          )}
        </div>
      </ModalTemplate>
    </>
  ) : (
    <></>
  );
};
