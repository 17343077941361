// @ts-nocheck
/* eslint-disable prefer-rest-params */
/* Error checking has been turned off for third party code */

export const initializeAnalytics = (gaTrackingId: string) => {
  /* Google Analytics code start */
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", gaTrackingId);
  /* Google Analytics code end */
};
