export interface PatchCurrentUser {
  first_name?: string;
  last_name?: string;
  company?: string;
  position?: string;
  username?: string;
  avatar_key?: string;
  social_links?: SocialMediaLink[];
}

export enum SocialMediaLinkType {
  TWITTER = "twitter",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  WEBSITE = "website",
}
export interface SocialMediaLink {
  type: SocialMediaLinkType;
  url: string;
}

export interface CurrentUser {
  unique_id: string;
  is_using_password?: boolean;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  company?: string;
  position?: string;
  avatar_key?: string;
  avatar_url: string;
  social_links?: SocialMediaLink[] | null;
  tz: string | null;
  following_count?: number;
  followers_count?: number;
  is_online?: boolean;
  is_deleted?: boolean;
}

export type GetUserResponse = {
  is_can_start_chat: boolean;
  is_following: boolean;
  privacy: UserPrivacy;
  user: Omit<CurrentUser, "is_using_password">;
};

export interface UploadImageKey {
  key: string | null;
}

export type PrivacyPublicity = "public" | "private";

export interface PrivacySettings {
  publicity: PrivacyPublicity;
  show_organizer_events: boolean;
  show_speaker_events: boolean;
  show_attendee_events: boolean;
  allow_others_to_start_chat: boolean;
}

export type Password = string;

export interface IPassword {
  password: Password;
}

export interface IUpdatePassword {
  old_password: Password;
  new_password: Password;
}
export interface ChangeEmailResponse {
  status: boolean;
}

export type UserAnalyticsResponse = {
  events_amount: number;
  events_last_month_diff_amount: number;
  duration_amount: number;
  duration_last_month_diff_amount: number;
  followers_amount: number;
  followers_last_month_diff_amount: number;
  attendees_amount: number;
  attendees_last_month_diff_amount: number;
};

export enum UserPrivacy {
  private = "private",
  public = "public",
}
