import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { MessengerViewVariant } from "src/close-part/features/Messenger/types";
import { useInitPrivateChatComplex } from "./useInitPrivateChatComplex";

export const useOpenNewPrivateChat = () => {
  const { goToView } = useMessengerViewController();

  const { initChatHandler, isLoading: isInitingChat } = useInitPrivateChatComplex();

  const openNewPrivateChat = async (userId: string) => {
    const chatDataRes = await initChatHandler({ userId });

    if (chatDataRes?.isSuccess) {
      goToView(MessengerViewVariant.CHAT_BY_ID, {
        chatId: chatDataRes.data.chat_id,
      });
    }

    if (chatDataRes?.isError) {
      // [CHAT] TODO: обработать ошибку ибо непонятно что должно
      // происходить в случае если чата нет и он не инитится - После релиза
    }
  };

  return { isInitingChat, openNewPrivateChat };
};
