import React, { FC } from "react";
import { IAmOrganizingTheEventMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: IAmOrganizingTheEventMetadata;
};

const IAmOrganizingEvent: FC<Props> = ({ metadata }) => {
  const {
    event_id: eventId,
    event_name: eventTitle,
    event_date: eventDate,
  } = metadata.data;

  return (
    <NotificationTemplate
      title="I'm organizing the event"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
      dateTime={{ date: eventDate }}
    />
  );
};
export default IAmOrganizingEvent;
