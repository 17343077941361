import { AppDispatch, RootState } from "src/store/store";
import { userApiEndpoints } from "src/store/User/api";
import { ChatDTO, NewMessageSocketEvent } from "../types";
// FIX_ME:
// eslint-disable-next-line import/no-cycle
import { messengerApi } from "../api";
import {
  getCurrentUser,
  normalizeLastMessage,
  normalizeMessage,
  updteChatListOnReceiveNewMessage,
  updateMessageListOnReceiveNewMessage,
  updateChatListOnDeleteChat,
  refreshChatMembers,
  isRefetchChatMembersCase,
  ChatUpdateMessageEvent,
} from "../lib";
import {
  EventInfoInChatUpdateMessage,
  isEventInfoInChatUpdateMessage,
} from "../lib/refresh-chat-members";

// TODO: переименовать
interface StoreArgs {
  dispatch: AppDispatch;
}

interface StoreArgsWithState extends StoreArgs {
  state: RootState;
}

const updateGroupChatInfoInChatRoom = (
  eventId: string,
  data: EventInfoInChatUpdateMessage,
) =>
  messengerApi.util.updateQueryData(
    "getChatInfoById",
    { chatId: eventId },
    (currentChatInfo) => ({
      ...currentChatInfo,
      title: data.title,
      avatar_url: data.avatar_url,
    }),
  );

const updateGroupChatInfoInChatList = (
  eventId: string,
  data: EventInfoInChatUpdateMessage,
) =>
  messengerApi.util.updateQueryData(
    "getChatList",
    { lastEvaluatedKey: null },
    (currentChatList) => {
      const updatedChatListData = currentChatList.data.map((chat: ChatDTO) =>
        chat.chat_id === eventId
          ? {
              ...chat,
              title: data.title,
              avatar_url: data.avatar_url,
            }
          : chat,
      ) as ChatDTO[];

      return { ...currentChatList, data: updatedChatListData };
    },
  );

export const onReceiveNewMessageHandler =
  ({ dispatch, state }: StoreArgsWithState) =>
  async (socketEventData: NewMessageSocketEvent) => {
    const currentUser = await getCurrentUser(state, dispatch);

    const chatId = socketEventData.message.chat_id;
    const isMessageFromCurrentUser =
      currentUser.unique_id === socketEventData.user.user_id;
    // TODO:
    const lastEvaluatedKey = null;

    const newLastMessage = normalizeLastMessage(socketEventData);

    dispatch(
      updteChatListOnReceiveNewMessage({
        chatId,
        lastEvaluatedKey,
        newLastMessage,
        isMessageFromCurrentUser,
      }),
    );

    if (!isMessageFromCurrentUser) {
      // NOTE: при получении нового сообщения по сокету от собеседника (!) формируем  newMessage и lastMessage
      // ибо нам нужно и добавлять новое сообщение и апдейтить последнее в ChatList
      const newMessage = normalizeMessage(socketEventData);
      dispatch(updateMessageListOnReceiveNewMessage({ chatId, newMessage }));
    }
  };

// [CHAT] TODO: [BE] на ChatUpdate приходит две разных (а может и больше) структур данных
// из них непонятно что произошло
// пока это реализовано так - непоняно как
export const onChatUpdateHandler =
  ({ dispatch }: StoreArgs) =>
  (data: ChatUpdateMessageEvent) => {
    console.log("[SOCKET:UPDATE_DIALOG]", data);

    if (isEventInfoInChatUpdateMessage(data)) {
      const eventId = data.id;
      dispatch(updateGroupChatInfoInChatRoom(eventId, data));
      dispatch(updateGroupChatInfoInChatList(eventId, data));
    }

    if (isRefetchChatMembersCase(data)) {
      dispatch(refreshChatMembers({ chatId: data.chat_id }));
    }
  };

export const onDeleteDialogHandler =
  ({ dispatch }: StoreArgs) =>
  (socketEventData: { chatId: string }) => {
    console.log("[SOCKET:DELETE_DIALOG]", socketEventData);

    // TODO:
    const lastEvaluatedKey = null;

    dispatch(
      updateChatListOnDeleteChat({
        lastEvaluatedKey,
        chatId: socketEventData.chatId,
      }),
    );

    dispatch(refreshChatMembers({ chatId: socketEventData.chatId }));
  };

export const onNewDialogHandler =
  ({ state, dispatch }: StoreArgsWithState) =>
  ({ chat_id }: { chat_id: string }) => {
    console.log("[SOCKET:NEW_DIALOG]", chat_id);

    const currentChatList = messengerApi.endpoints.getChatList.select({
      lastEvaluatedKey: null,
    })(state);

    const lastEvaluatedKey = currentChatList.isUninitialized
      ? null
      : currentChatList.data?.lastEvaluatedKey;

    dispatch(
      messengerApi.endpoints.getChatList.initiate(
        { lastEvaluatedKey },
        { forceRefetch: true },
      ),
    );

    dispatch(
      messengerApi.endpoints.getChatInfoById.initiate(
        { chatId: chat_id },
        { forceRefetch: true },
      ),
    );
  };

export const onUserUpdateHandler =
  ({ dispatch }: StoreArgs) =>
  (userData: { user_id: string; name: string; user_avatar: string }) => {
    // [CHAT] TODO: [BE] в идеале нужно чтобы приходил и chat_id (но выглядит это слегка нереальным)
    // и в то же время мы не можем проходиться по каждому из чатов и искать нужного юзера который проапдейтился
    // мы можем только инвалидировать все тэги касающиеся всех участиников каждого из чатов
    // но тогда нам придется делать запросы снова на каждый из юзер апдейтов
    // непонятно выгодно ли это? зависит от того как часто приходят апдейты юзера
    // dispatch(refreshChatMembers({ chatId })); После релиза
    dispatch(
      userApiEndpoints.getUser.initiate(
        { userId: userData.user_id },
        { forceRefetch: true },
      ),
    );

    console.log("[SOCKET:USER_UPDATE]", userData);
  };
