// FIX_ME:
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Button } from "@evvve/ui-kit";
import style from "./style.module.scss";

export interface PhotoLoaderProps {
  acceptedTypes: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
}

const { wrap } = style;

const UploaderButton = ({ acceptedTypes, onChange, disabled }: PhotoLoaderProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (hiddenFileInput) {
      hiddenFileInput.current?.click();
    }
  };

  return (
    <div className={wrap}>
      {/* FIX_ME: */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={style.input}>
        <input
          type="file"
          accept={acceptedTypes}
          onChange={onChange}
          ref={hiddenFileInput}
          disabled={disabled}
        />
        <Button
          type="flat"
          theme="violet"
          disabled={disabled}
          startIcon="Image"
          onClick={handleClick}
        >
          Upload
        </Button>
      </label>
    </div>
  );
};

export default UploaderButton;
