/* eslint-disable dot-location */
// TODO: этот компонент нужно будет декомпозировать позже ТЕХДОЛГ
import React, {
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import {
  refetchFollowingList,
  updateCachedUserCardInChat,
  updateFollowingCountInProfile,
} from "src/store/User/followOrUnfollow";
import { useFollowOrUnfollowUserMutation } from "src/store/User/api";
import { useAppSelector } from "src/hooks/useStoreHooks";
import ContextMenu, { ContextMenuTypes } from "src/shared-features/ContextMenu";
import { useModal } from "src/hooks";
import { Button } from "@evvve/ui-kit";
import { ChatDTO, ChatVariant, messengerApi } from "src/store/messenger";
import { Nullable } from "src/shared/utils/utility-types";
import { GetUserResponse } from "src/store/User/types";
import { useMessengerViewController } from "src/close-part/features/Messenger/hooks";
import { MessengerViewVariant } from "src/close-part/features/Messenger/types";
import { useDispatch } from "react-redux";
import s from "./styles.module.scss";
import { QuitChatModal } from "../quit-chat-modal";

interface Props {
  children?: ReactNode | undefined;
  menuIsOpened: boolean;
  closeMenu: VoidFunction;
  menuButtonRef: RefObject<HTMLDivElement>;
  chatInfo?: ChatDTO;
  // TODO: странное название типа ибо юзер может быть и не current
  chatMateProfile?: GetUserResponse;
  isFetchingChatMateProfile?: boolean;
}

type MenuPosition = {
  left: number;
  top: number;
};

const useDeleteChatComplex = () => {
  const [deleteChatMutation, { isLoading: isDeleteChatProcessing }] =
    messengerApi.useDeleteChatMutation();

  const [leaveChatMutation, { isLoading: isLeaveChatProcessing }] =
    messengerApi.useLeaveChatMutation();

  const onDeleteChat = ({
    chatId,
    chatType,
  }: {
    chatId: string;
    chatType: ChatVariant;
  }) => {
    if (chatType === ChatVariant.GROUP) return leaveChatMutation({ chatId });
    if (chatType === ChatVariant.PRIVATE) return deleteChatMutation({ chatId });

    throw new Error("[MESSENGER]: Wrong chatType");
  };

  return {
    onDeleteChat,
    isFetching: isDeleteChatProcessing || isLeaveChatProcessing,
  };
};

const ContextMenuWrapper: FC<Props> = ({
  children,
  menuIsOpened,
  closeMenu,
  menuButtonRef,
  chatInfo,
  chatMateProfile,
  isFetchingChatMateProfile,
}) => {
  const dispatch = useDispatch();

  const currentUser = useAppSelector((state) => state.user.userInfo);

  const [menuPosition, setMenuPosition] = useState<Nullable<MenuPosition>>(null);
  const { goToParticipants, goToView, goToChatList } = useMessengerViewController();

  const { onDeleteChat, isFetching: deleteChatIsProcessing } =
    useDeleteChatComplex();

  const [followOrUnfollow, { isLoading: isFollowOrUnfollowProcessing }] =
    useFollowOrUnfollowUserMutation();

  const { isOpenModal: isModalOpened, openModal, closeModal } = useModal();

  const isPrivateChat = chatInfo && chatInfo.type === ChatVariant.PRIVATE;

  const updateMenuPosition = useCallback(() => {
    if (menuButtonRef.current) {
      const rectBoundary = menuButtonRef.current.getBoundingClientRect();
      const left = rectBoundary.right;
      const { top } = rectBoundary;
      setMenuPosition({ left, top });
    }
  }, [menuButtonRef]);

  useLayoutEffect(() => {
    if (menuIsOpened) updateMenuPosition();
  }, [menuIsOpened, updateMenuPosition]);

  const quitChat = () => {
    const currentChatId = chatInfo?.chat_id;

    if (!currentChatId) throw new Error("[CHAT]: There is no chatId");

    onDeleteChat({ chatId: currentChatId, chatType: chatInfo.type })
      .then(() => {
        closeModal();
        goToChatList();
      })
      .catch(() => {
        throw new Error("[CHAT]: Deliting pocess is failed");
      });
  };

  const onLeaveChat = () => {
    closeMenu();
    openModal();
  };

  // TODO: подумать как лучше вынести вовне
  const groupChatMenuActions = [
    {
      type: ContextMenuTypes.ViewAllParticipants,
      handler: () => goToParticipants(chatInfo?.chat_id),
    },
    {
      type: ContextMenuTypes.LeaveGroup,
      handler: onLeaveChat,
    },
  ];

  // TODO: подумать как лучше вынести вовне
  const privateChatMenuActions = [
    {
      type: ContextMenuTypes.ViewProfile,
      handler: () => {
        goToView(MessengerViewVariant.PROFILE, {
          profileId: chatMateProfile?.user.unique_id,
        });
      },
      isLoading: !chatMateProfile,
    },
    {
      type: chatMateProfile?.is_following
        ? ContextMenuTypes.UnfollowUser
        : ContextMenuTypes.FollowUser,
      handler: async () => {
        if (!chatMateProfile || !currentUser) return;
        closeMenu();

        const userId = chatMateProfile.user.unique_id;

        const doIFollowUser = chatMateProfile.is_following;
        const updatedFollowedOrUnfollowedUser = await followOrUnfollow({
          doIFollowUser,
          userId,
        }).unwrap();

        // Counter in Profile renders the currentUser.following_count instead of followingList.length,
        // so we need to update both (counter and Following list in user card in Profile)
        dispatch(refetchFollowingList);
        dispatch(
          updateFollowingCountInProfile(
            currentUser,
            doIFollowUser ? "unfollow" : "follow",
          ),
        );
        // Follow <-> Unfollow button in menu
        dispatch(updateCachedUserCardInChat(updatedFollowedOrUnfollowedUser));
      },
      isLoading: isFollowOrUnfollowProcessing || isFetchingChatMateProfile,
    },
    {
      type: ContextMenuTypes.DeleteDialog,
      handler: onLeaveChat,
    },
  ];

  // TODO: подумать как лучше вынести вовне
  const modalActionsEl = (
    <div className={s.leaveGroupModalButtons}>
      <Button type="outline" theme="violet" onClick={closeModal}>
        Cancel
      </Button>
      <Button
        type="solid"
        theme="violet"
        onClick={quitChat}
        disabled={deleteChatIsProcessing}
        isLoading={deleteChatIsProcessing}
      >
        Delete
      </Button>
    </div>
  );

  return (
    <>
      {children}
      {menuIsOpened && (
        <ContextMenu
          position={{
            left: menuPosition?.left,
            top: menuPosition?.top,
          }}
          onClickBlockingArea={closeMenu}
          actions={isPrivateChat ? privateChatMenuActions : groupChatMenuActions}
        />
      )}
      {chatInfo && (
        <QuitChatModal
          isOpenModal={isModalOpened}
          onClose={closeModal}
          dialogType={chatInfo.type}
          avatarUrl={chatInfo.avatar_url}
          dialogCaption={chatInfo.title}
          buttons={modalActionsEl}
        />
      )}
    </>
  );
};

export default ContextMenuWrapper;
