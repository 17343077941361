import React, { useEffect } from "react";
import { ModalControls, useModal } from "./useModal";
import BrowserInformationModal from "../shared-features/Modals/BrowserInformationModal";
import CookiesModal from "../shared-features/Modals/CookiesModal";

export const useInfoModals = (): JSX.Element => {
  const KEY_BROWSER = "note-browser";
  const KEY_COOKIES = "note-cookies";
  const VALUE = "true";

  const browserInfoModal = useModal();
  const cookiesModal = useModal(false);

  const onCloseModal = (key: string, modalControls: ModalControls) => {
    localStorage.setItem(key, VALUE);
    modalControls.closeModal();
  };
  const onCloseCookiesModal = () => onCloseModal(KEY_COOKIES, cookiesModal);
  const onCloseBrowserInfoModal = () => onCloseModal(KEY_BROWSER, browserInfoModal);

  const openModalOnce = (
    key: string,
    modalControls: ModalControls,
    extraCondition: boolean = true,
  ) => {
    const wasModalShown = localStorage.getItem(key) === VALUE;
    if (!wasModalShown && extraCondition) {
      modalControls.openModal();
    }
  };

  useEffect(() => {
    const { userAgent } = navigator;
    const isChrome =
      (/Chrome/.test(userAgent) || /CriOS/.test(userAgent)) &&
      !userAgent.includes("Edg");
    openModalOnce(KEY_BROWSER, browserInfoModal, !isChrome);
    openModalOnce(KEY_COOKIES, cookiesModal);
  }, [browserInfoModal, cookiesModal]);

  return (
    <>
      <BrowserInformationModal
        modalControls={browserInfoModal}
        onClose={onCloseBrowserInfoModal}
      />
      <CookiesModal modalControls={cookiesModal} onClose={onCloseCookiesModal} />
    </>
  );
};
