// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import { Avatar, Text } from "@evvve/ui-kit";
import cn from "classnames";
import style from "./style.module.scss";

export interface UserSummaryProps {
  avatar: string;
  name: string;
  username?: string;
  className?: string;
  companyOrUsernameClassname?: string;
  isCurrentUser?: boolean;
  company?: string;
  theme?: "dark" | "light";
}

const UserSummary = ({
  avatar,
  name,
  username,
  company,
  className,
  companyOrUsernameClassname,
  isCurrentUser,
  theme = "dark",
}: UserSummaryProps) => (
  <div className={cn(style.userSummary, className)}>
    <Avatar
      status="offline"
      size="s"
      src={avatar}
      alt=""
      className={style.user_picture}
    />
    <div>
      <Text
        color={theme === "dark" ? "grayscale1000" : "white"}
        size="m"
        className={style.oneRow}
      >
        {name}
        {isCurrentUser && (
          <Text as="span" size="m" bold>
            &nbsp;(Me)
          </Text>
        )}
      </Text>
      {company && (
        <Text
          color={theme === "dark" ? "grayscale600" : "grayscale300"}
          size="xs"
          className={cn(style.oneRow, companyOrUsernameClassname)}
        >
          {company}
        </Text>
      )}
      {username && (
        <Text
          color="grayscale600"
          size="xs"
          className={cn(style.oneRow, companyOrUsernameClassname)}
        >
          {username}
        </Text>
      )}
    </div>
  </div>
);
export default UserSummary;
