import { ModalBack } from "src/shared-features/Modals";
import style from "./style.module.scss";
import HeaderClosePart from "../../HeaderClosePart";
import Triangle from "./triangle.svg";
// eslint-disable-next-line import/no-cycle
import { Messenger } from "../../Messenger";

interface ModalChatProps {
  onClose: () => void;
  isOpen: boolean;
}

const ModalChat = (props: ModalChatProps) => {
  const { isOpen, onClose } = props;

  return (
    <ModalBack isOpen={isOpen} onClose={onClose} className={style.isHiddenOnDesktop}>
      <div className={style.wrap}>
        <div className={style.header_wrap}>
          {/* TODO: очень интересует зачем в модалку с чатом пробрасывается целый header??? К иcправлению ТЕХДОЛГ */}
          <HeaderClosePart onClickChat={onClose} isOpenChat={isOpen} />
        </div>
        <div className={style.inner}>
          <Messenger />
          <img src={Triangle} alt="white Triangle" className={style.triangle} />
        </div>
      </div>
    </ModalBack>
  );
};

export default ModalChat;
