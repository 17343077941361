// FIX_MEP
/* eslint-disable react/no-unstable-nested-components */
import { Input, Text } from "@evvve/ui-kit";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikProps } from "formik/dist/types";
import { required } from "src/helpers/validation";
import { InputMaxLength } from "src/constants";
import { useValidationByInput } from "src/hooks/useValidationByInput";
import { useDeleteAccountMutation } from "../../../../../store/User/api";
import pageStyle from "../AccessDetails/style.module.scss";
import style from "./DeleteAccount.module.scss";
import { DeleteAccountModal } from "../../../../features/Modals";
import { useLogout } from "../../../../../authorization/hooks/useLogout";

const VALID_PHRASE = "If you love someone, let them go";

export interface DeleteAccountFormProps {
  phrase: string;
}

export const DeleteAccount = () => {
  const validationSchema = Yup.object().shape({
    phrase: required("Please enter the phrase", InputMaxLength.XXL_1024).oneOf(
      [VALID_PHRASE],
      "Please check that you entered the phrase correctly",
    ),
  });

  const [deleteAccount] = useDeleteAccountMutation();

  const { onLogOut } = useLogout();

  const DeleteAccountForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    validateForm,
    setFieldError,
    setFieldTouched,
  }: FormikProps<DeleteAccountFormProps>) => {
    const isSubmitDisabled = useValidationByInput(touched, errors);

    return (
      <form id="testId" onBlur={handleBlur} onSubmit={(e) => handleSubmit(e)}>
        <div className={style.deletion}>
          <Text size="m" color="red700" bold>
            Delete account
          </Text>
          <Text size="s" color="grayscale900">
            Sorry to hear you want to delete your account. Enter a phrase “If you
            love someone, let them go” to confirm that you are doing it on purpose.
          </Text>
        </div>
        <div className={style.input}>
          <Input
            name="phrase"
            value={values.phrase}
            placeholder="Type the phrase here"
            onChange={(e) => {
              setFieldError("phrase", "");
              handleChange(e);
            }}
            error={errors.phrase}
          />
        </div>
        <div className={pageStyle.fullwidth}>
          <DeleteAccountModal
            disabled={isSubmitDisabled}
            onOpen={(openModal: () => void) => {
              setFieldTouched("phrase");
              validateForm().then((errs) => {
                if (!errs.phrase) {
                  openModal();
                }
              });
            }}
            onAccept={() => handleSubmit()}
          />
        </div>
      </form>
    );
  };

  return (
    <Formik<DeleteAccountFormProps>
      validateOnChange={false}
      validateOnBlur
      initialValues={{ phrase: "" }}
      onSubmit={() =>
        deleteAccount()
          .unwrap()
          .then(() => onLogOut())
      }
      validationSchema={validationSchema}
      component={DeleteAccountForm}
    />
  );
};
