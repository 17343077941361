import { Text } from "@evvve/ui-kit";
import React from "react";
import { SocialMediaBlock } from "src/shared-features/UserCard/SocialMediaBlock/SocialMediaBlock";
import s from "../../style.module.scss";
// eslint-disable-next-line import/no-cycle
import { PersonCardBlockProps } from "../../../../../Person/types";

export const CompanyAndSocialMediaBlock = ({
  profileOwner,
  ...responsiveLayout
}: PersonCardBlockProps) => (
  <div className={s.companyAndSocialMediaBlock}>
    {(profileOwner.position || profileOwner.company) && (
      <div>
        {profileOwner.position && <Text size="m">{profileOwner.position}</Text>}
        {profileOwner.company && <Text size="m">{profileOwner.company}</Text>}
      </div>
    )}
    {!!profileOwner.social_links?.length && (
      <SocialMediaBlock profileOwner={profileOwner} {...responsiveLayout} />
    )}
  </div>
);
