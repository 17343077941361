import { Spinner } from "@evvve/ui-kit";
import { useCallback, useRef } from "react";
import { useAppDispatch } from "src/hooks/useStoreHooks";
import { refetchFollowingList } from "src/store/User/followOrUnfollow";
import cn from "classnames";
// eslint-disable-next-line import/no-cycle
import { ModalTemplate } from "src/close-part/features/Modals";
import { GetUserResponse } from "src/store/User/types";
import { ModalControls } from "src/hooks/useModal";
import style from "./follow.module.scss";
import { FollowListItem } from "./FollowListItem";

interface FollowListProps {
  modalControls: ModalControls;
  title: string;
  userList?: GetUserResponse[];
  isMobile?: boolean;
}

export const FollowListModal = ({
  title,
  userList,
  modalControls,
  isMobile,
}: FollowListProps) => {
  const MAX_HEIGHT_IN_USERS = 5;

  const dispatch = useAppDispatch();

  const shouldRefetchFollowingList = useRef(false);

  const onFollowOrUnfollow = useCallback(() => {
    if (!shouldRefetchFollowingList.current) {
      shouldRefetchFollowingList.current = true;
    }
  }, []);

  const onCloseModal = () => {
    if (shouldRefetchFollowingList.current) {
      dispatch(refetchFollowingList);
    }
    modalControls.closeModal();
  };

  return (
    <ModalTemplate
      title={title}
      isOpen={modalControls.isOpenModal}
      onClose={onCloseModal}
      className={cn(style.followListModal, {
        [style.followListModal_mobile]: isMobile,
      })}
    >
      {userList && (
        <div
          className={cn(style.followList, {
            [style.followListWithScroll]: userList.length > MAX_HEIGHT_IN_USERS,
          })}
        >
          {userList.map((userInfo) => (
            <FollowListItem
              key={userInfo.user.unique_id}
              userInfo={userInfo}
              onFollowOrUnfollowButtonClick={onFollowOrUnfollow}
            />
          ))}
        </div>
      )}
      {!userList && <Spinner size="l" className={style.spinnerWrap} />}
    </ModalTemplate>
  );
};
