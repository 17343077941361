import React, { FC } from "react";

import { Tabs } from "@evvve/ui-kit";
import { ChatList } from "./ui";
import styles from "./style.module.scss";

const ChatListView: FC = () => (
  <>
    <Tabs activeTab="chat" onTabChange={() => {}} className={styles.tabs}>
      <Tabs.Tab tabId="chat" label="Chat" />
    </Tabs>
    <ChatList />
  </>
);

export default ChatListView;
