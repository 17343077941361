import React, { FC } from "react";
import { Text } from "@evvve/ui-kit";
import avatarSkeleton from "src/assets/images/avatar_skeleton.png";
import { CurrentUser } from "src/store/User/types";
import s from "./styles.module.scss";
import { getFullName } from "../../../../../../../store/events/helpers";

type Props = {
  profileInfo: Omit<CurrentUser, "is_using_password">;
};

const ProfileHead: FC<Props> = ({ profileInfo }) => (
  <div className={s.head}>
    <img
      className={s.avatar}
      src={profileInfo?.avatar_url || avatarSkeleton}
      alt=""
    />
    <div>
      <Text size="m" fontWeight={600} className={s.text}>
        {getFullName(profileInfo)}
      </Text>
      <Text size="s" color="violet600" className={s.text}>
        {`@${profileInfo?.username}`}
      </Text>
    </div>
  </div>
);

export default ProfileHead;
