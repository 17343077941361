import React, { FC } from "react";
import cn from "classnames";
import { Icons } from "@evvve/ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { noop } from "lodash";
import s from "./style.module.scss";
import { useGetChatMateComplex, useMessengerViewController } from "../../hooks";
import { MessengerViewVariant } from "../../types";
import {
  MessageButton,
  ProfileHead,
  ProfileSkeleton,
  SubscriptionButton,
} from "./ui";
import { useAppSelector } from "../../../../../hooks/useStoreHooks";
import ChatSpinner from "../chat-spinner/ChatSpinner";
// eslint-disable-next-line import/no-cycle
import { FollowCounterBlock } from "../../../../../shared-features/UserCard/FollowCounterBlock/FollowCounterBlock";
// eslint-disable-next-line import/no-cycle
import { CompanyAndSocialMediaBlock } from "./ui/profile-additional-info/CompanyAndSocialMediaBlock";
import { UserPrivacy } from "../../../../../store/User/types";

type LocationState = {
  previousSearch: string;
};

const ProfileView: FC = () => {
  const { goToView } = useMessengerViewController();

  const { chatMateProfile, isLoading } = useGetChatMateComplex();

  const navigate = useNavigate();
  const locationState = useLocation().state as LocationState;
  const responsiveLayout = useAppSelector(
    (state) => state.user.responsiveLayoutConfig,
  );

  const currentUser = useAppSelector((state) => state.user.userInfo);
  const {
    user: userProfileInfo,
    privacy,
    is_following: doIFollowProfileOwner,
    is_can_start_chat: profileOwnerAllowsMessages,
  } = chatMateProfile || {};

  const closeProfileViewHandler = () => {
    if (locationState?.previousSearch) navigate(locationState.previousSearch);
    else goToView(MessengerViewVariant.CHAT_BY_ID);
  };

  const hasJobInfo = userProfileInfo?.position || userProfileInfo?.company;
  const hasSocialMediaLinks =
    userProfileInfo?.social_links && userProfileInfo.social_links.length > 0;

  const canSendMessageToProfileOwner =
    privacy === UserPrivacy.public && profileOwnerAllowsMessages;

  const isCurrentUserProfileOwner =
    !!currentUser &&
    !!userProfileInfo &&
    currentUser.unique_id === userProfileInfo.unique_id;

  const shouldShowsSkeleton =
    (!isCurrentUserProfileOwner && privacy === UserPrivacy.private) ||
    !responsiveLayout;
  const shouldShowPreloader = (isLoading && !chatMateProfile) || !userProfileInfo;
  if (shouldShowPreloader) return <ChatSpinner />;

  return (
    <div className={cn(s.wrap)}>
      {/* TODO kit: Icons: add onClick, className, style props  */}
      <div
        className={s.closeButton}
        onClick={closeProfileViewHandler}
        role="button"
        onKeyDown={noop}
        tabIndex={0}
        aria-labelledby="TODO:"
      >
        <Icons.X size="m" />
      </div>
      <div className={s.content}>
        <ProfileHead profileInfo={userProfileInfo} />
        {shouldShowsSkeleton ? (
          <ProfileSkeleton />
        ) : (
          <>
            <div className={s.interactionWithAnotherUserBlock}>
              <FollowCounterBlock
                profileOwner={userProfileInfo}
                isCurrentUserProfileOwner={isCurrentUserProfileOwner}
                isMobile={false}
                isTablet={false}
                isDesktop
                className={s.followCounterBlock}
              />
              {!isCurrentUserProfileOwner && (
                <>
                  <SubscriptionButton
                    amIFollower={!!doIFollowProfileOwner}
                    userId={userProfileInfo.unique_id}
                    disabled={isCurrentUserProfileOwner}
                  />
                  <MessageButton
                    isAbleToChat={canSendMessageToProfileOwner}
                    userFirstName={userProfileInfo.first_name}
                    userId={userProfileInfo.unique_id}
                  />
                </>
              )}
            </div>
            {(hasJobInfo || hasSocialMediaLinks) && (
              <CompanyAndSocialMediaBlock
                profileOwner={userProfileInfo}
                {...responsiveLayout}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileView;
