import React from "react";

import { NotificationTemplate } from "../notification-template";

const welcomeToEvvveTextStatic = (
  <>
    We’re so glad you joined us.
    <br />
    Through this system dialogue, you will receive important notifications from our
    platform.
  </>
);

const WelcomeToPlatform = () => (
  <NotificationTemplate title="Welcome to Evvve!">
    {welcomeToEvvveTextStatic}
  </NotificationTemplate>
);

export default WelcomeToPlatform;
